// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700|Cedarville+Cursive|Special+Elite&display=swap);
// Font Variables
$montserrat: 'Montserrat', sans-serif;
$cedarville-cursive: 'Cedarville Cursive', cursive;
$special-elite: 'Special Elite', cursive;

// Theme Fonts:
$main-font: $montserrat;
$typewiter-font: $special-elite;
$handwriting-font: $cedarville-cursive;

// Color Variables
$gray: #d9d9d9;
$white: #ffffff;
$paper: #fffefa;
$black: #383838;


$primary-color: #4ec7ad;
// Text color when displayed when $primary-color is background
$foreground-primary-color: #f6f6f6;

$main-font-color: #606060;

$main-background-color: $white;
// Color to use when layering cards/backgrounds
$secondardy-background-color: darken($main-background-color, 3%)
