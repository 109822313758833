@import '../theme.scss';

$white-transparent: rgba($white, .5);
$black-transparent: rgba($black, .5);

body{
  color: $main-font-color;
  font-family: $main-font;
}

.--effect-grow {
  transition-duration: 1.5s;
  transition-timing-function: ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
}
