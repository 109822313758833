@import "../style.scss";

.story-moment-layout-timeline {

  .story-moment__content__body {

    padding: 0 50px;
    padding-bottom: 40vh;
    background: $foreground-primary-color;

      .story-timeline__element {

        .vertical-timeline-element-content{
          padding: 0;
        }

        .vertical-timeline-element-content:after{
          content: unset;
        }
      }
  }
}
