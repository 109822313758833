@import '../../core/styles.scss';

.#{$root-element} {
  --scale-out-scale: 0.85;

  &--exit {
    &.#{$root-element}--moveRight,
    &.#{$root-element}--moveLeft {
      animation: scaleOut var(--slider-transition-duration) both
        var(--transition-bezier);
    }
  }
}

@keyframes scaleOut {
  to {
    transform: scale(var(--scale-out-scale));
  }
}
